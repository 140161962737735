import axios from "axios";
const baseUrl = process.env.VUE_APP_BACKEND_URL;
import {can, token_expired} from '@/acl/methods'

export default {
  state: {
    listaPersonas: [],
  },
  mutations: {
    SET_LISTA_PERSONAS(state, value) {
      state.listaPersonas = value;
    },
  },
  actions: {
    getListaPersonas({ commit, getters }, params) {
      if (token_expired(getters.user)){
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}mi-poa/personas/lista`, {
            params,
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then((res) => {
            commit("SET_LISTA_PERSONAS", res.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    registerPersona({ getters, commit },form) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'users', ['post'])){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .post(`${baseUrl}mi-poa/registerpersona`, form, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              this.$toast.success(data.message);
              resolve(data.id);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("No tiene permisos para editar usuarios");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  },
  getters: {
    listaPersonas: (state) => state.listaPersonas,
  },
};
