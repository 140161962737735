/* eslint-disable */
import axios from "axios";
const backendUrl = process.env.VUE_APP_BACKEND_URL; 

export default {
    state: {
        perros: [],
        inscriptos: [],
        torneos: [],
        calendarios: [],

        isFetching: false,


        current_page_1: null,
        from_1: null,
        perpage_1: null,
        to_1: null,
        total_1: null,

        current_page_2: null,
        from_2: null,
        perpage_2: null,
        to_2: null,
        total_2: null,
    },
    mutations: {
        SET_TORNEOS(state, value) {
            state.torneos = [];
            value.map((val) => {
                state.torneos.push({
                    value: val.torneo_cod,
                    name: val.torneo_desc,
                    torneo_ingreso_por_tramites: val.torneo_ingreso_por_tramites,
                    torneos_es_interagrupacion: val.torneos_es_interagrupacion,
                    torneos_es_nacional: val.torneos_es_nacional,
                    torneos_es_regional: val.torneos_es_regional,
                    torneos_es_interagrup_interior: val.torneos_es_interagrup_interior,
                });
            });
        },
        SET_CALENDARIOS(state, value) {
            state.calendarios = [];
            value.map((val) => {
                state.calendarios.push(
                    {
                        value: val.calendario_ide,
                        name: `${new Date(val.fecha_hasta).getDate() + 1}-${new Date(val.fecha_hasta).getMonth() + 1}-${new Date(val.fecha_hasta).getFullYear()} ${val.fecha_nombre}`,
                        cant_cuotas: val.cantcuotas,
                        fecha_desde: val.fecha_desde,
                        fecha_hasta: val.fecha_hasta,
                        delegacion_codigo: val.delegacion_codigo,
                        agrupacion_codigo: val.agrupacion_codigo,
                        categoria_codigo: val.categoria_codigo
                    });
            });
        },
        SET_PERROS(state, value) {
            state.perros = value;
        },
        SET_PAGINATION_INFO_0(state, value) {
            state.current_page_1 = value.current_page;
            state.from_1 = value.from;
            state.perpage_1 = value.perpage;
            state.to_1 = value.to;
            state.total_1 = value.total;
        },
        SET_PERROS_INSCRIPTOS(state, value) {
            let inscriptos = value;
            inscriptos.map((can, index) => {
                inscriptos[index].user_info = state.user_info;
                if (can.duenio == null) {
                    inscriptos[index].duenio = can.criadero;
                    inscriptos[index].agrupacion = can.criadero_agrupacion;
                    inscriptos[index].delegacion = can.criadero_delegacion;

                }

            });
            state.inscriptos = inscriptos;
        },
        SET_PAGINATION_INFO_1(state, value) {
            state.current_page_2 = value.current_page;
            state.current_page_2 = value.current_page;
            state.from_2 = value.from;
            state.perpage_2 = value.perpage;
            state.to_2 = value.to;
            state.total_2 = value.total;
        },
        NOTIFICATE_INSCRIPCION(state, data) {
            console.log(data);
            axios.post(`${backendUrl}mail/`, {
                from: `noreply@clubpoa.com.ar`,
                to: `poa@clubpoa.com.ar`,
                subject: `Nueva Inscripción`,
                text: `

<div style="text-align:center; background:#65c178; padding: 1em 0;">
<h1 style="color:white;">Nueva inscripción realizada</h1>

<div style="background:#363636; width:fit-content; margin: auto; padding: 1em 5em;">

<h3 style="color:white;">Cliente</h3>
<p style="color:white;">Agrupación: ${data.agrupacion ??= ""} | ${data.agrupacion_codigo ??= "999"}</p>
<p style="color:white;">Delegacion: ${data.delegacion ??= ""} | ${data.delegacion_codigo ??= "999"}</p>
<p style="color:white;">Nombre: ${data.duenio ??= ""} | ${data.estado_cliente}</p>


<h3 style="color:white;">Torneo | Fecha</h3>
<p style="color:white;">Torneo: ${data.torneo.name}</p>
<p style="color:white;">Calendario: ${data.calendario.value ??= ""} | ${data.calendario.name}</p>



<h3 style="color:white;">Can</h3>
<p style="color:white;">Can: ${data.can_codigo ??= ""} | ${data.can_nombre ??= ""} | ${data.fecha_nacimiento ??= ""}</p>
<p style="color:white;">Inscripto por: ${data.user_info.cliente_codigo}</p>

<br/>
<br/>
<p style="color:white;">La sincronización de esta inscripción puede hacerse manualmente presionando el boton "Sincronizar manualmente".
   En caso contrario, la sincronización se realizara automaticamente durante el dia.
</p>
<br/>
<br/>
<a style="background:#65c178; padding: 1em; text-decoration: none; color:white;" href="http://syncweb:8000">Sincronizar manualmente</a>
<br/>
<br/>

</div>

</div>
                
`
            });
        },
        SET_FETCHING(state, value) {
            state.isFetching = value;
        },
        CLEAR_PERROS(state) {
            state.perros = [];
            return true;
        },
        CLEAR_INSCRIPTOS(state) {
            state.inscriptos = [];
            return true;
        },
    },
    actions: {
        async findTorneos({ commit }) {
            await axios.post(`${backendUrl}torneos`).then((response) => {
                commit('SET_TORNEOS', response.data);
            });
            return 0;
        },
        async findCalendarios({ commit }, torneo_cod) {
            await axios.post(`${backendUrl}torneos/calendarios`, { torneo_cod: torneo_cod }).then((response) => {
                commit('SET_CALENDARIOS', response.data);
            });
            return 0;
        },
        async findPerros({ commit }, params) {
            await axios.post(`${backendUrl}torneos/usuario/canes`, { user: params.user, data: params.data, calendario: params.calendario }).then((response) => {
                commit('SET_PERROS', response.data);
                commit('SET_PAGINATION_INFO_0', response.data);
            });
            return 0;
        },
        async findInscriptos({commit}, params) {
            await axios.post(`${backendUrl}torneos/usuario/inscriptos`, {user: params.user, data: params.data}).then((response) => {
                if(response.data.errMsg) {this.$toast.error(response.data.errMsg); return;}
                commit('SET_PERROS_INSCRIPTOS', response.data);
                commit('SET_PAGINATION_INFO_1', response.data);
            });
        },
        async showError({ commit }, msg) {
            this.$toast.error(msg);
        },
        showMsg({ commit }, msg) {
            this.$toast.success(msg);
        },
        async inscribirCan({ commit }, data) {
            axios.post(`${backendUrl}torneos/inscribir`, { data: data }).then((response) => {
                if (response.data.msg) {
                    this.$toast.success(response.data.msg);
                    commit('NOTIFICATE_INSCRIPCION', data);
                }
                else if (response.data.errMsg)
                    this.$toast.error(response.data.errMsg);
            });
        },
        async setFetching({commit}, params) {
            commit("SET_FETCHING", params);
        },
        async clearPerros({commit}) {
            await commit('CLEAR_PERROS');
            return true;
        },
        async clearInscriptos({commit}) {
            await commit('CLEAR_INSCRIPTOS');
            return true;
        },
    },
    getters: {
        getTorneos(state) {
            return state.torneos;
        },
        getCalendarios(state) {
            return state.calendarios;
        },
        getPerros(state) {
            console.log({

            });

            return {
                total: state.total_1,
                perpage: state.perpage_1,
                current_page: state.current_page_1,
                from: (state.perpage_1 * state.current_page_1) - state.perpage_1,
                to: state.perpage_1 * state.current_page_1,
                last_page: Math.floor(state.total_1 / state.perpage_1),
                data: state.perros
            };
        },
        getInscriptos(state) {
            return {
                total: state.total_2,
                perpage: state.perpage_2,
                current_page: state.current_page_2,
                from: (state.perpage_2 * state.current_page_2) - state.perpage_2,
                to: state.perpage_2 * state.current_page_2,
                last_page: Math.floor(state.total_2 / state.perpage_2),
                data: state.inscriptos
            };
        },
        getUserInfo(state) {
            return state.user_info;
        },
        isFetching(state) {
            return state.isFetching;
        }
    }
}