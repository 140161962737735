<template>
  <div class="table-responsive">
    <table class="table table-bordered table-hover">
      <thead>
         <slot name="headers"></slot>
      </thead>
      <tbody>
         <slot name="rows"></slot>
      </tbody>
    </table>
  </div>
</template>

<script setup>

</script>

<style>
td.min {
  width: 1%;
  white-space: nowrap;
}
</style>

<!-- 

HEADERS, METER EL V-FOR EN TH Y GENRAR LOS CAMPOS DEL HEADER

<template #headers>
  <tr>
    <th scope="col" """"v-for """">#</th>
  </tr>
</template>



ROWS, METER V-FOR EN TR, Y USAR SOLO PRIMER TH, Y EL RESTO EN TD

<template #rows>
  <tr """""v-for """">
    <th scope="row"> </th>
    <td>Mark</td>
    <td>Mark</td>
    <td>Mark</td>
  </tr>
</template>

 -->