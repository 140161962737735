const baseUrl = process.env.VUE_APP_BACKEND_URL;
import axios from "axios";
import {token_expired} from '@/acl/methods'

export default {
  state: {
    videos: {},
  },
  mutations: {
    SET_VIDEOS(state, value) {
      state.videos = value;
    },
  },
  actions: {
    getVideos({getters, commit }, params) {
      if (token_expired(getters.user)){
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}videos`, {
            params,
          })
          .then((res) => {
            commit("SET_VIDEOS", res.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    createVideo({ getters, dispatch, commit }, form) {
      if (token_expired(getters.user)){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .post(`${baseUrl}videos`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getVideos");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    deleteVideo({ getters, dispatch, commit }, id) {
      if (token_expired(getters.user)){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .delete(`${baseUrl}videos/${id}`, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getVideos");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    
  },
  getters: {
    videos: (state) => state.videos,
  },
};
