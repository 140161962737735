const baseUrl = process.env.VUE_APP_BACKEND_URL;
import axios from "axios";
import {token_expired} from '@/acl/methods'

export default {
  state: {
    files: [],
    revistas: []
  },
  mutations: {
    SET_FILES(state, value) {
      state.files = value;
    },
    CLEAR_FILES(state) {
      state.files = [];
    },
  },
  actions: {
    getFiles({getters, commit}, {id_galeria, params}) {
      if (token_expired(getters.user)) {
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}files/galeria/${id_galeria}`, {
              params,
            })
            .then((res) => {
              commit("SET_FILES", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    createFile({getters, dispatch, commit}, form) {
      if (token_expired(getters.user)) {
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .post(`${baseUrl}files`, form, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({data}) => {
              dispatch("getFiles", {id_galeria: form.get("id_galeria")});
              this.$toast.success(data);
              resolve(data);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },

    uploadRevista({commit, dispatch, getters}, value) {
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios.post(`${baseUrl}revistas/subir`, value,
          {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          }).then(async (response) => {
            if (response.status === 200) {
              this.$toast.success("Revista subida!")
            }
            await dispatch("getRevistas")
            commit("HIDE_LOADER");
            resolve();
          }
        )
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });

      });
    },

    getRevistas({commit, state, getters}, value) {
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios.post(`${baseUrl}revistas`, value, {headers: {Authorization: `Bearer ${getters.token}`}}).then((response) => {
          if (response.status === 200) {
            state.revistas = response.data.revistas;

          }
          commit("HIDE_LOADER");
          resolve();
        });
      });
    },

    deleteRevista({commit, dispatch, getters}, value) {
      const headers = {headers: {Authorization: `Bearer ${getters.token}`}};
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios.post(`${baseUrl}revistas/delete`, value, headers).then(async (response) => {
          if (response.status !== 200) {
            this.$toast.error("Error al eliminar revista")
          }
          await dispatch("getRevistas");
          commit("HIDE_LOADER");
          resolve();
        });
      });
    },

    deleteFile({getters, dispatch, commit}, {id, id_galeria}) {
      if (token_expired(getters.user)) {
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .delete(`${baseUrl}files/${id}`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({data}) => {
              dispatch("getFiles", {id_galeria});
              this.$toast.success(data);
              resolve(data);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  },
  getters: {
    files: (state) => state.files,
    revistas: (state) => state.revistas,
  },
};
