const baseUrl = process.env.VUE_APP_AUTH_BACKEND_URL;
import axios from "axios";
import {can, token_expired} from '@/acl/methods'

export default {
  state: {
    recursos: {},
    recursosNoPaginado: {},
  },
  mutations: {
    SET_RECURSOS(state, value) {
      state.recursos = value;
    },
    SET_RECURSOSNOPAGINADOS(state, value) {
      state.recursosNoPaginado = value;
    },
  },
  actions: {
    getRecursosNoPaginados({ commit, getters }, params) {
      if (token_expired(getters.user)){
      // Se usa para actualizar Accesos
      if (can(getters.user, 'roles', ['put'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}recursos/nopaginado`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              params,
            })
            .then((res) => {
              commit("SET_RECURSOSNOPAGINADOS", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      }else{
        commit("SET_RECURSOSNOPAGINADOS", {rows: []});
      }}else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  },
  getRecursos({ commit, getters }, params) {
    if (token_expired(getters.user)){
    if (can(getters.user, 'roles', ['get'])){
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}recursos`, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
            params,
          })
          .then((res) => {
            commit("SET_RECURSOS", res.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });
      }else{
        commit("SET_RECURSOS", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  getters: {
    recursos: (state) => state.recursos,
    recursosNoPaginado: (state) => state.recursosNoPaginado,
    
  },
};
