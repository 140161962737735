const baseUrl = process.env.VUE_APP_BACKEND_URL;
import axios from "axios";
import {can, token_expired} from '@/acl/methods'

export default {
  state: {
    galeriasPublicas: [],
    galeriasPublicasByName: [],
    // listaGalerias:[],
  },
  mutations: {
    SET_GALERIAS_PUBLICAS(state, value) {
      state.galeriasPublicas = value;
    },
    SET_GALERIAS_PUBLICAS_GROUPBYNAME(state, value) {
      state.galeriasPublicasByName = value;
    },
    // SET_LISTA_GALERIAS(state, value) {
    //   state.listaGalerias = value;
    // }
  },
  actions: {
    getGaleriasPublicas({getters, commit }, params) {
      if (token_expired(getters.user)){
      if (can(this.getters.user, 'galerias', ['get'])){
        commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}galeriaPublica`,{
              headers:{
                Authorization: `Bearer ${getters.token}`
              },
              params,
            })
          .then((res) => {
            commit("SET_GALERIAS_PUBLICAS", res.data);
            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        commit("SET_GALERIAS_PUBLICAS", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getGaleriasPublicasGroupName({getters, commit }, params) {
      if (token_expired(getters.user)){
      if (can(this.getters.user, 'galerias', ['get'])){
        commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}galeriaPublica/galeriaPublica/groupbyName`,{
              headers:{
                Authorization: `Bearer ${getters.token}`
              },
              params,
            })
          .then((res) => {
            commit("SET_GALERIAS_PUBLICAS_GROUPBYNAME", res.data);
            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        commit("SET_GALERIAS_PUBLICAS_GROUPBYNAME", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    deleteGaleriaPublica({getters, commit, dispatch }, id) {
      if (token_expired(getters.user)){
      if (can(this.getters.user, 'galerias', ['delete'])){
        commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .delete(`${baseUrl}galeriaPublica/${id}`,{
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({data}) => {
            dispatch('getGaleriasPublicas')
            this.$toast.success(data);
            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        commit("SET_GALERIAS", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    deleteGaleriaPublicaIMG({getters, commit, dispatch }, id) {
      if (token_expired(getters.user)){
      if (can(this.getters.user, 'galerias', ['delete'])){
        commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .delete(`${baseUrl}galeriaPublica/IMG/${id}`,{
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({data}) => {
            dispatch('getGaleriasPublicas')
            this.$toast.success(data);
            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        commit("SET_GALERIAS", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    deleteGaleriaPublicaVIDEO({getters, commit, dispatch }, id) {
      if (token_expired(getters.user)){
      if (can(this.getters.user, 'galerias', ['delete'])){
        commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .delete(`${baseUrl}galeriaPublica/VIDEO/${id}`,{
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({data}) => {
            dispatch('getGaleriasPublicas')
            this.$toast.success(data);
            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        commit("SET_GALERIAS", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    // getListaGalerias({ commit, getters }, params) {
    //   if (token_expired(getters.user)){
    //   return new Promise((resolve) => {
    //     axios
    //       .get(`${baseUrl}galerias/listar`, {
    //         params,
    //         headers: {
    //           Authorization: `Bearer ${getters.token}`,
    //         },
    //       })
    //       .then((res) => {
    //         commit("SET_LISTA_GALERIAS", res.data);
    //         resolve();
    //       })
    //       .catch(this.$errorHandler);
    //   });
    //   }else{
    //     this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
    //     this.dispatch("logout");
    //   }
    // },
    // createGaleria({ getters, dispatch, commit }, form) {
    //   if (token_expired(getters.user)){
    //   if(can(getters.user, 'galerias', ['post'])){
    //   commit("SHOW_LOADER");
    //   return new Promise((resolve) => {
    //     axios
    //       .post(`${baseUrl}galerias`, form, {
    //         headers: {
    //           Authorization: `Bearer ${getters.token}`,
    //         },
    //       })
    //       .then(({ data }) => {
    //         dispatch("getGalerias");
    //         this.$toast.success(data.message);
    //         resolve(data);
    //       })
    //       .catch(this.$errorHandler)
    //       .finally(() => {
    //         commit("HIDE_LOADER");
    //       });
    //   });}
    //   else{
    //     this.$toast.error("No tiene permisos para realizar esta acción");
    //   }
    //   }else{
    //     this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
    //     this.dispatch("logout");
    //   }
    // },
    // deleteGaleria({ getters, dispatch, commit }, id) {
    //   if (token_expired(getters.user)){
    //   if(can(getters.user, 'galerias', ['delete'])){
    //   commit("SHOW_LOADER");
    //   return new Promise((resolve) => {
    //     axios
    //       .delete(`${baseUrl}galerias/${id}`, {
    //         headers: {
    //           Authorization: `Bearer ${getters.token}`,
    //         },
    //       })
    //       .then(({ data }) => {
    //         dispatch("getGalerias");
    //         this.$toast.success(data);
    //         resolve(data);
    //       })
    //       .catch(this.$errorHandler)
    //       .finally(() => {
    //         commit("HIDE_LOADER");
    //       });
    //   });}
    //   else{
    //     this.$toast.error("No tiene permisos para realizar esta acción");
    //   }
    //   }else{
    //     this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
    //     this.dispatch("logout");
    //   }
    // },
    // updateGaleria({ getters, dispatch, commit }, {id, form}) {
    //   if (token_expired(getters.user)){
    //   if(can(getters.user, 'galerias', ['put'])){
    //   commit("SHOW_LOADER");
    //   return new Promise((resolve) => {
    //     axios
    //       .put(`${baseUrl}galerias/${id}`, form, {
    //         headers: {
    //           Authorization: `Bearer ${getters.token}`,
    //         },
    //       })
    //       .then(({ data }) => {
    //         dispatch("getGalerias");
    //         this.$toast.success(data);
    //         resolve(data);
    //       })
    //       .catch(this.$errorHandler)
    //       .finally(() => {
    //         commit("HIDE_LOADER");
    //       });
    //   });}
    //   else{
    //     this.$toast.error("No tiene permisos para realizar esta acción");
    //   }
    //   }else{
    //     this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
    //     this.dispatch("logout");
    //   }
    // },
  },
  getters: {
    galeriasPublicas: (state) => state.galeriasPublicas,
    galeriasPublicasByName: (state) => state.galeriasPublicasByName,
    // listaGalerias: (state) => state.listaGalerias,
  },
};
