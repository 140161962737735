import axios from "axios";
const baseUrl = process.env.VUE_APP_BACKEND_URL;
import {token_expired} from '@/acl/methods'

export default {
  state: {
    categoriasTorneos: [],
    listaCategoriasTorneos: [],
    listaJuecesTipo: [],
  },
  mutations: {
    SET_CATEGORIAS_TORNEOS(state, value) {
      state.categoriasTorneos = value;
    },
    SET_CATEGORIAS_TORNEOS_LISTA(state, value) {
      state.listaCategoriasTorneos = value;
    },
    SET_JUECES_TIPO_LISTA(state, value) {
      state.listaJuecesTipo = value;
    },
  },
  actions: {
    getCategoriasTorneos({ commit, getters }, params) {
      if (token_expired(getters.user)){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}categorias-torneos`, {
              params,
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then((res) => {
              commit("SET_CATEGORIAS_TORNEOS", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getListaCategoriasTorneos({ commit, getters }, params) {
      if (token_expired(getters.user)){
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}categorias-torneos/lista`, {
            params,
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then((res) => {
            commit("SET_CATEGORIAS_TORNEOS_LISTA", res.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getListaJuecesTipo({ commit, getters }, params) {
      if (token_expired(getters.user)){
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}categorias-torneos/jueces_tipo/lista`, {
            params,
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then((res) => {
            commit("SET_JUECES_TIPO_LISTA", res.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    createCategoriaTorneo({ getters, dispatch, commit }, form) {
      if (token_expired(getters.user)){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .post(`${baseUrl}categorias-torneos`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getCategoriasTorneos");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    updateCategoriaTorneo({ getters, dispatch, commit }, { id, form }) {
      if (token_expired(getters.user)){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .put(`${baseUrl}categorias-torneos/${id}`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getCategoriasTorneos");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    deleteCategoriaTorneo({ getters, dispatch, commit }, id) {
      if (token_expired(getters.user)){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .delete(`${baseUrl}categorias-torneos/${id}`, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getCategoriasTorneos");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  },
  getters: {
    categoriasTorneos: (state) => state.categoriasTorneos,
    listaCategoriasTorneos: (state) => state.listaCategoriasTorneos,
    listaJuecesTipo: (state) => state.listaJuecesTipo,
  },
};
