const baseUrl = process.env.VUE_APP_AUTH_BACKEND_URL;
import axios from "axios";
import {can, canPG, token_expired} from '@/acl/methods'

export default {
  state: {
    roles: {},
    noRoles: {},
    role: {},
    rolesNoPaginado: {},
  },
  mutations: {
    SET_ROLES(state, value) {
      state.roles = value;
    },
    SET_ROLESNOPAGINADO(state, value) {
      state.rolesNoPaginado = value;
    },
    SET_NOROLES(state, value) {
      state.noRoles = value;
    },
    SET_ROLE(state, value){
      state.role = value;
    },
  },
  actions: {
    getRolesNoPaginado({ commit, getters }, params) {
      if (token_expired(getters.user)){
      // Pongo Post, porque se usa para crear nuevo ROL
      if (can(getters.user, 'roles', ['post'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}roles/nopaginado`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              params,
            })
            .then((res) => {
              commit("SET_ROLESNOPAGINADO", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_ROLESNOPAGINADO", {rows: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getRoles({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'roles', ['get'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}roles`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              params,
            })
            .then((res) => {
              commit("SET_ROLES", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_ROLES", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    // NO SE USA
    // getRole({commit, getters }, id) {
    //   if (can(getters.user, 'roles', ['get'])){
    //     return new Promise((resolve) => {
    //       axios
    //         .get(`${baseUrl}roles/${id}`, {
    //           headers: {
    //             Authorization: `Bearer ${getters.token}`,
    //           },
    //         })
    //         .then((res) => {
    //           commit("SET_ROLE", res.data);
    //           resolve();
    //         })
    //         .catch(this.$errorHandler);
    //     });
    //   } else {
    //     commit("SET_ROLE", {data: []});
    //   }
    // },
    createRole({ getters, dispatch, commit }, form) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'roles', ['post'])){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .post(`${baseUrl}roles/`, form, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              dispatch("getRoles");
              this.$toast.success(data.message);
              resolve(data.id);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("No tiene permisos para crear roles");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    updateRole({ getters, dispatch, commit }, { id, form }) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'roles', ['put'])){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .put(`${baseUrl}roles/${id}`, form, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              dispatch("getRoles");
              this.$toast.success(data);
              resolve(data);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("No tiene permisos para actualizar roles");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    deleteRole({ getters, dispatch, commit }, id) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'roles', ['delete'])){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .delete(`${baseUrl}roles/${id}`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              dispatch("getRoles");
              this.$toast.success(data);
              resolve(data);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("No tiene permisos para eliminar roles");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getRolesDeleted({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'roles', ['get']) && canPG(getters.user, 'g_d_role')){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}roles/get/deleted`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              params,
            })
            .then((res) => {
              commit("SET_NOROLES", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_NOROLES", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  restoreRole({ getters, dispatch, commit }, id) {
    if (token_expired(getters.user)){
    if (can(getters.user, 'roles', ['put']) && canPG(getters.user, 'p_rest_role')){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .put(`${baseUrl}roles/restore/${id}`,"_", {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              dispatch("getRolesDeleted");
              this.$toast.success(data);
              resolve(data);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("No tiene permisos para restaurar roles");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
      },
  },
  getters: {
    roles: (state) => state.roles,
    rolesNoPaginado: (state) => state.rolesNoPaginado,
    noRoles: (state) => state.noRoles,
    role: (state) => state.role,
  },
};
