import axios from "axios";
const baseUrl = process.env.VUE_APP_BACKEND_URL;
import {token_expired} from '@/acl/methods'

export default {
  state: {
    jueces: [],
  },
  mutations: {
    SET_JUECES(state, value) {
      state.jueces = value;
    },
  },
  actions: {
    getJueces({ commit, getters }, params) {
      if (token_expired(getters.user)){
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}jueces`, {
            params,
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then((res) => {
            commit("SET_JUECES", res.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    createJuez({ getters, dispatch, commit }, form) {
      if (token_expired(getters.user)){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .post(`${baseUrl}jueces`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getJueces");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    updateJuez({ getters, dispatch, commit }, { id, form }) {
      if (token_expired(getters.user)){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .put(`${baseUrl}jueces/${id}`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getJueces");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    deleteJuez({ getters, dispatch, commit }, id) {
      if (token_expired(getters.user)){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .delete(`${baseUrl}jueces/${id}`, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getJueces");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  },
  getters: {
    jueces: (state) => state.jueces,
  },
};
