const baseUrl = process.env.VUE_APP_BACKEND_URL;
import axios from "axios";
import {can, token_expired} from '@/acl/methods'

export default {
  state: {
    links: [],
  },
  mutations: {
    SET_LINKS(state, value) {
      state.links = value;
    },
  },
  actions: {
    getLinks({ commit,getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'links', ['get'])){
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}links`, {
            params,
          })
          .then((res) => {
            commit("SET_LINKS", res.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });}
      else {
        commit("SET_LINKS", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    createLink({ getters, dispatch, commit }, form) {
      if (token_expired(getters.user)){
      if(can(getters.user, 'links', ['post'])){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .post(`${baseUrl}links`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getLinks");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        this.$toast.error('No tiene permisos para realizar esta acción');
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    updateLink({ getters, dispatch, commit }, { id, form }) {
      if (token_expired(getters.user)){
      if(can(getters.user, 'links', ['put'])){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .put(`${baseUrl}links/${id}`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getLinks");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        this.$toast.error('No tiene permisos para realizar esta acción');
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    deleteLink({ getters, dispatch, commit }, id) {
      if (token_expired(getters.user)){
      if(can(getters.user, 'links', ['delete'])){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .delete(`${baseUrl}links/${id}`, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getLinks");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        this.$toast.error('No tiene permisos para realizar esta acción');
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  },
  getters: {
    links: (state) => state.links,
  },
};
