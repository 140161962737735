import axios from "axios";
import { flatChildren } from "@/utils/flatChildren";
import {can} from '@/acl/methods'
const baseUrl = process.env.VUE_APP_BACKEND_URL;
import {token_expired} from '@/acl/methods'

export default {
  state: {
    categorias: [],
    listaCategorias:[],
  },
  mutations: {
    SET_CATEGORIAS(state, value) {
      state.categorias = value;
      state.categorias.data = state.categorias.data.map((categoria, index) => {
        return {
          ...categoria,
          pos: value.data[index].pos + 1,
        };
      });
    },
    SET_LISTA(state, value) {
      let aux = Object.values(value);
      aux = flatChildren(aux);
      aux = [{id: null, nombre: 'Sin Categoría'}, {id: 0, nombre: 'Menu Principal', pos: 0},...aux];
      state.listaCategorias = aux;
    },
  },
  actions: {
    getCategorias({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'categorias', ['get'])){
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}categorias`, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
            params,
          })
          .then((res) => {
            commit("SET_CATEGORIAS", res.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });}
      else{
        commit("SET_CATEGORIAS", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    createCategoria({ getters, dispatch, commit }, form) {
      if (token_expired(getters.user)){
      if(can(getters.user, 'categorias', ['post'])){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .post(`${baseUrl}categorias`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getCategorias");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        this.$toast.error("No tiene permisos para crear categorias");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    updateCategoria({ getters, dispatch, commit }, { id, form }) {
      if (token_expired(getters.user)){
      if(can(getters.user, 'categorias', ['put'])){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .put(`${baseUrl}categorias/${id}`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getCategorias");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        this.$toast.error("No tiene permisos para actualizar categorias");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    deleteCategoria({ getters, dispatch, commit }, id) {
      if (token_expired(getters.user)){
      if(can(getters.user, 'categorias', ['delete'])){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .delete(`${baseUrl}categorias/${id}`, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getCategorias");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        this.$toast.error("No tiene permisos para eliminar categorias");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getArbolCategorias({ commit, getters }, params) {
      if (token_expired(getters.user)){
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}categorias/listar-arbol`, {
            params,
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then((res) => {
            commit("SET_LISTA", res.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    recoverCategoria({ getters,dispatch, commit }, id) {
      if (token_expired(getters.user)){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .put(`${baseUrl}categorias/restore/${id}`,'_', {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getCategorias");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  },
  getters: {
    categorias: (state) => state.categorias,
    listaCategorias: (state) => state.listaCategorias,
  },
};
