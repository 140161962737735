import VueTable from "./VueTable.vue";
import VueTablePermisosGenerales from "./VueTablePermisosGenerales.vue";
import VueTableCoincidenciasCheckAll from "./VueTableCoincidenciasCheckAll.vue";
import TableBoostrapComponent from "./TableBoostrapComponent.vue";

export default {
  install: (app) => {
    app.component("vue-table", VueTable);
    app.component("vue-table-permisosgenerales", VueTablePermisosGenerales);
    app.component("vue-table-coincidenciascheckall", VueTableCoincidenciasCheckAll);
    app.component("vue-table-boostrap", TableBoostrapComponent);
  },
};
