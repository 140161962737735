export const modules = {
  USERS:1,
  ROLES:2,
  CATEGORIAS:3,
  CONTENIDOS:4,
  FILES:5,
  GALERIAS:6,
  LINKS:7,
  CALENDARIO:8,
}

export const perms = {
  GET:1,
  POST:2,
  PUT:3,
  DELETE:4
}

export const permisosGenerales = {
  G_D_USER:1,
  G_D_ROLE:2,
  P_REST_USER:3,
  P_REST_ROLES:4,
  G_D_GENERAL:5,
  P_REST_GENERAL:6,
}

export const CheckRoles = {
  SysAdmin:99,
  APPTOKEN:101,
  Socio:1,
  Admin:2,
}
