const baseUrl = process.env.VUE_APP_AUTH_BACKEND_URL;
import axios from "axios";
import {can, canPG, token_expired} from '@/acl/methods'

export default {
  state: {
    users: {},
    noUsers: {},
  },
  mutations: {
    SET_USERS(state, value) {
      state.users = value;
    },
    SET_NOUSERS(state, value) {
      state.noUsers = value;
    }
  },
  actions: {
    getUsers({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'users', ['get'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}users`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              params,
            })
            .then((res) => {
              commit("SET_USERS", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_USERS", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getUsersDeleted({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'users', ['get']) && canPG(getters.user, 'g_d_user')){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}users/list/deleted`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              params,
            })
            .then((res) => {
              commit("SET_NOUSERS", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_NOUSERS", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    updatePersonaId({ getters, dispatch, commit }, { id, form }) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'users', ['put','post'])){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .put(`${baseUrl}users/persona/${id}`, form, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              dispatch("getUsers");
              this.$toast.success(data);
              resolve(data);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("No tiene permisos para editar usuarios");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    updateUser({ getters, dispatch, commit }, { id, form }) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'users', ['put'])){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .put(`${baseUrl}users/${id}`, form, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              dispatch("getUsers");
              this.$toast.success(data);
              resolve(data);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("No tiene permisos para editar usuarios");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    deleteUser({ getters, dispatch, commit }, id) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'users', ['delete'])){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .delete(`${baseUrl}users/${id}`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              dispatch("getUsers");
              this.$toast.success(data);
              resolve(data);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("No tiene permisos para eliminar usuarios");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    restoreUser({ getters, dispatch, commit }, id) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'users', ['put']) && canPG(getters.user, 'p_rest_user')){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .put(`${baseUrl}users/restore/${id}`,"_", {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              dispatch("getUsersDeleted");
              this.$toast.success(data);
              resolve(data);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("No tiene permisos para restaurar usuarios");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  },
  getters: {
    users: (state) => state.users,
    noUsers: (state) => state.noUsers,
  },
};
