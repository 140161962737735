const baseUrl = process.env.VUE_APP_AUTH_BACKEND_URL;
import axios from "axios";
import {can, token_expired} from '@/acl/methods'

export default {
  state: {
    rolePermisosGenerales: {},
  },
  mutations: {
    SET_ROLEPERMISOSGENERALES(state, value) {
      state.rolePermisosGenerales = value;
    },
  },
  actions: {
    getRolePermisosGenerales({ commit, getters }, id,params) {
      if (token_expired(getters.user)){
      // Pongo Post y Put, porque se usa para crear y actualizar nuevo ROL
      if (can(getters.user, 'roles', ['post','put'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}rolepermisosgenerales/clonacion/role/${id}`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              params,
            })
            .then((res) => {
              commit("SET_ROLEPERMISOSGENERALES", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_ROLEPERMISOSGENERALES", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    createRolePermisosGenerales({ getters, commit }, {id,form}) {
      if (token_expired(getters.user)){
      // Se usa para crear los Accesos del nuevo Rol al momento de crear el rol
      if (can(getters.user, 'roles', ['post'])){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .post(`${baseUrl}rolepermisosgenerales/${id}`, form, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              this.$toast.success(data.message);
              resolve(data.id);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("No tiene permisos para crear permisos generales");
      }}else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }

    },
    updateRolePermisosGenerales({ getters, commit }, {id,form}) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'roles', ['put'])){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .put(`${baseUrl}rolepermisosgenerales/${id}`, form, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              this.$toast.success(data);
              resolve(data);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("No tiene permisos para editar permisos generales");
      }}else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  },
  getters: {
    rolePermisosGenerales: (state) => state.rolePermisosGenerales,
  },
};
