const baseUrl = process.env.VUE_APP_AUTH_BACKEND_URL;
import axios from "axios";
import {can,token_expired} from '@/acl/methods'

export default {
  state: {
    permisos: {},
  },
  mutations: {
    SET_PERMISOS(state, value) {
      state.permisos = value;
    },
  },
  actions: {
    getPermisos({ commit, getters }, params) {
      if (token_expired(getters.user)){
      // Se usa para actualizar Accesos
      if (can(getters.user, 'roles', ['put'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}permisos`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              params,
            })
            .then((res) => {
              commit("SET_PERMISOS", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      }else{
        commit("SET_PERMISOS", {rows: []});
      }
    
    }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    }
  },
  getters: {
    permisos: (state) => state.permisos,
    
  },
};
