const baseUrl = process.env.VUE_APP_BACKEND_URL;
import axios from "axios";
import {token_expired} from '@/acl/methods'

export default {
  state: {
    filesPublicos: [],
  },
  mutations: {
    SET_FILES_PUBLICOS(state, value) {
      state.filesPublicos = value;
    },
    CLEAR_FILES_PUBLICOS(state) {
      state.filesPublicos = [];
    },
  },
  actions: {
    getFilesPublicos({getters, commit }, {id, params}) {
      if (token_expired(getters.user)){
        commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}galeriaPublica/${id}`,{
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
            params,
          })
          .then((res) => {
            commit("SET_FILES_PUBLICOS", res.data[0]);
            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getFilesPublicosByName({getters, commit }, {id, params}) {
      if (token_expired(getters.user)){
        commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}galeriaPublica/galeriaPublica/groupbyName/${id}`,{
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
            params,
          })
          .then((res) => {
            commit("SET_FILES_PUBLICOS", res.data);
            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    moveFilesPublicas({getters, commit}, {id, form}){
      if (token_expired(getters.user)){
        commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .put(`${baseUrl}galeriaPublica/vincularFilesNew/${id}`,form,{
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({data}) => {
            this.$toast.success(data);
            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    }
    // createFile({ getters, dispatch, commit }, form) {
    //   if (token_expired(getters.user)){
    //   commit("SHOW_LOADER");
    //   return new Promise((resolve) => {
    //     axios
    //       .post(`${baseUrl}files`, form, {
    //         headers: {
    //           Authorization: `Bearer ${getters.token}`,
    //         },
    //       })
    //       .then(({ data }) => {
    //         dispatch("getFiles", {id_galeria: form.get("id_galeria")});
    //         this.$toast.success(data);
    //         resolve(data);
    //       })
    //       .catch(this.$errorHandler)
    //       .finally(() => {
    //         commit("HIDE_LOADER");
    //       });
    //   });
    //   }else{
    //     this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
    //     this.dispatch("logout");
    //   }
    // },
    // deleteFile({ getters, dispatch, commit }, {id, id_galeria}) {
    //   if (token_expired(getters.user)){
    //   commit("SHOW_LOADER");
    //   return new Promise((resolve) => {
    //     axios
    //       .delete(`${baseUrl}files/${id}`, {
    //         headers: {
    //           Authorization: `Bearer ${getters.token}`,
    //         },
    //       })
    //       .then(({ data }) => {
    //         dispatch("getFiles", {id_galeria});
    //         this.$toast.success(data);
    //         resolve(data);
    //       })
    //       .catch(this.$errorHandler)
    //       .finally(() => {
    //         commit("HIDE_LOADER");
    //       });
    //   });
    //   }else{
    //     this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
    //     this.dispatch("logout");
    //   }
    // },
  },
  getters: {
    filesPublicos: (state) => state.filesPublicos,
  },
};
