import axios from "axios";
import {can, token_expired} from '@/acl/methods'
const baseUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  state: {
    contenidos: [],
     contenidos1: [],
      contenidos2: [],
       contenidos3: [],
        contenidos4: [],
         contenidos5: [],
          contenidos6: [],
           contenidos7: [],
            contenidos8: [],
  },
  mutations: {
    SET_CONTENIDOS1(state, value) {
      state.contenidos1 = value;
      state.contenidos1.data = state.contenidos1.data.map((contenido, index) => {
        return {
          ...contenido,
          pos: value.data[index].pos + 1,
        };
      });
    },
    SET_CONTENIDOS2(state, value) {
      state.contenidos2 = value;
      state.contenidos2.data = state.contenidos2.data.map((contenido, index) => {
        return {
          ...contenido,
          pos: value.data[index].pos + 1,
        };
      });
    },SET_CONTENIDOS3(state, value) {
      state.contenidos3 = value;
      state.contenidos3.data = state.contenidos3.data.map((contenido, index) => {
        return {
          ...contenido,
          pos: value.data[index].pos + 1,
        };
      });
    },
    SET_CONTENIDOS4(state, value) {
      state.contenidos4 = value;
      state.contenidos4.data = state.contenidos4.data.map((contenido, index) => {
        return {
          ...contenido,
          pos: value.data[index].pos + 1,
        };
      });
    },
    SET_CONTENIDOS5(state, value) {
      state.contenidos5 = value;
      state.contenidos5.data = state.contenidos5.data.map((contenido, index) => {
        return {
          ...contenido,
          pos: value.data[index].pos + 1,
        };
      });
    },SET_CONTENIDOS6(state, value) {
      state.contenidos6 = value;
      state.contenidos6.data = state.contenidos6.data.map((contenido, index) => {
        return {
          ...contenido,
          pos: value.data[index].pos + 1,
        };
      });
    },SET_CONTENIDOS7(state, value) {
      state.contenidos7 = value;
      state.contenidos7.data = state.contenidos7.data.map((contenido, index) => {
        return {
          ...contenido,
          pos: value.data[index].pos + 1,
        };
      });
    },SET_CONTENIDOS8(state, value) {
      state.contenidos8 = value;
      state.contenidos8.data = state.contenidos8.data.map((contenido, index) => {
        return {
          ...contenido,
          pos: value.data[index].pos + 1,
        };
      });
    },
    SET_CONTENIDOS(state, value) {
      console.log(value);
      state.contenidos = value;
      if(state.contenidos.data) {
      state.contenidos.data = state.contenidos.data.map((contenido, index) => {
          return {
            ...contenido,
            pos: value.data[index].pos + 1,
          };
        });
      }
    },
  },
  // TODO: Aca hay una prueba de como funciona can
  actions: {
    getContenidos8({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'contenidos', ['get'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}contenidos`, {
              headers:{
                Authorization: `Bearer ${getters.token}`
              },
              params,
            })
            .then((res) => {
              commit("SET_CONTENIDOS8", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_CONTENIDOS8", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getContenidos7({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'contenidos', ['get'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}contenidos`, {
              headers:{
                Authorization: `Bearer ${getters.token}`
              },
              params,
            })
            .then((res) => {
              commit("SET_CONTENIDOS7", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_CONTENIDOS7", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getContenidos6({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'contenidos', ['get'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}contenidos`, {
              headers:{
                Authorization: `Bearer ${getters.token}`
              },
              params,
            })
            .then((res) => {
              commit("SET_CONTENIDOS6", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_CONTENIDOS6", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getContenidos5({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'contenidos', ['get'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}contenidos`, {
              headers:{
                Authorization: `Bearer ${getters.token}`
              },
              params,
            })
            .then((res) => {
              commit("SET_CONTENIDOS5", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_CONTENIDOS5", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getContenidos4({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'contenidos', ['get'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}contenidos`, {
              headers:{
                Authorization: `Bearer ${getters.token}`
              },
              params,
            })
            .then((res) => {
              commit("SET_CONTENIDOS4", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_CONTENIDOS4", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getContenidos3({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'contenidos', ['get'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}contenidos`, {
              headers:{
                Authorization: `Bearer ${getters.token}`
              },
              params,
            })
            .then((res) => {
              commit("SET_CONTENIDOS3", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_CONTENIDO3", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getContenidos2({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'contenidos', ['get'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}contenidos`, {
              headers:{
                Authorization: `Bearer ${getters.token}`
              },
              params,
            })
            .then((res) => {
              commit("SET_CONTENIDOS2", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_CONTENIDOS2", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
     getContenidos1({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'contenidos', ['get'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}contenidos`, {
              headers:{
                Authorization: `Bearer ${getters.token}`
              },
              params,
            })
            .then((res) => {
              commit("SET_CONTENIDOS1", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_CONTENIDOS1", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getContenidos({ commit, getters }, params) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'contenidos', ['get'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}contenidos`, {
              headers:{
                Authorization: `Bearer ${getters.token}`
              },
              params,
            })
            .then((res) => {
              commit("SET_CONTENIDOS", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_CONTENIDOS", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    createContenido({ getters, dispatch, commit }, form) {
      if (token_expired(getters.user)){
        if(can(getters.user, 'contenidos', ['post'])){
          commit("SHOW_LOADER");
          return new Promise((resolve) => {
        axios
          .post(`${baseUrl}contenidos`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getContenidos");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
        else{
          this.$toast.error("No tiene permisos para crear contenidos");
        }
      } else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    updateContenido({ getters, dispatch, commit }, { id, form }) {
      if (token_expired(getters.user)){
      if(can(getters.user, 'contenidos', ['put'])){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .put(`${baseUrl}contenidos/${id}`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getContenidos");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        this.$toast.error("No tiene permisos para editar contenidos");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    deleteContenido({ getters, dispatch, commit }, id) {
      if (token_expired(getters.user)){
      if(can(getters.user, 'contenidos', ['delete'])){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .delete(`${baseUrl}contenidos/${id}`, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getContenidos");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        this.$toast.error("No tiene permisos para eliminar contenidos");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  },
  getters: {
    contenidos: (state) => state.contenidos,
    contenidos1: (state) => state.contenidos1,
    contenidos2: (state) => state.contenidos2,
    contenidos3: (state) => state.contenidos3,
    contenidos4: (state) => state.contenidos4,
    contenidos5: (state) => state.contenidos5,
    contenidos6: (state) => state.contenidos6,
    contenidos7: (state) => state.contenidos7,
    contenidos8: (state) => state.contenidos8,
  },
};
