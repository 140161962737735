export const flatChildren = (categorias, pos) => {
  let aux = [];
  if (pos === undefined) {
    pos = 0;
  }
  categorias.forEach((categoria) => {
    aux.push({id: categoria.id, nombre: categoria.nombre, pos: pos+1});
    if (categoria.hijos) {
      aux = [...aux, ...flatChildren(categoria.hijos, pos + 1)];
    }
  });
  return aux;
}