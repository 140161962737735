import { createStore } from "vuex";
import loader from "./_loader";
import auth from "./_auth";
import news from "./_news";
import videos from "./_videos";
import posts from "./_posts";
import contacts from "./_contacts";
import files from "./_files";
import links from "./_links";
import users from "./_users";
import roles from "./_roles";
import permisos from "./_permisos";
import recursos from "./_recursos";
import categorias from "./_categorias";
import galerias from './_galerias';
import recursosrolespermisos from './_recursos.roles.permisos';
import contenidos from "./_contenidos";
import permisosGenerales from "./_permisos.generales";
import rolePermisosGenerales from "./_roles.permisos.generales";
import categoriasTorneos from "./_categorias_torneos";
import jueces from "./_jueces";
import personas from "./_personas";
import comite from "./_comite";
import calendario from "./_calendario"
import galeriasPublicas from './_galerias.publicas'
import filesPublicos from './_files.publicos'
import torneos from './_torneos'

export default createStore({
  modules: {
    auth,
    loader,
    news,
    videos,
    posts,
    contacts,
    files,
    links,
    users,
    roles,
    categorias,
    galerias,
    contenidos,
    recursosrolespermisos,
    permisos,
    recursos,
    permisosGenerales,
    rolePermisosGenerales,
    categoriasTorneos,
    jueces,
    personas,
    comite,
    calendario,
    galeriasPublicas,
    filesPublicos,
    torneos
  },
});
