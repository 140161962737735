<template>
  <div>
    <div class="v-table-responsive-container">
      <table :class="opts.tableClass">
        <thead :class="opts.theadClass">
          <tr> 
            <th class="text-start">
              {{  titleRows }}
            </th>
            <th class="text-center">
              Seleccionar Todos
            </th>
            <template v-for="head in headers" :key="head.id">
              <th class="text-center"
                :style="{ width: head.width ? head.width + '%' : 'auto' }"
              >
                {{ head.mask.ucwords() }}
              </th>
            </template>
            <th style="width: 1%" />
          </tr>
        </thead>
        <tbody :class="opts.tbodyClass">
          
            <template v-for="(row, a) in rows" :key="row.id || a">
              <tr>
                <th class="text-start">
                  {{row.mask.ucwords()}}
                </th>
                <th>
                  <input class="v-table-checkbox"
                    v-model="CheckAll[a]"
                    @click="changeStateAll(row.check,$event.target.checked)"
                    @change="changeCheckRows({rowId: row.id, available: headersLength, checked:$event.target.checked})"
                    :checked="CheckAll[a]"
                    type="checkbox" id="checkbox">
                </th>
                <td
                  v-for="(head, b) in headers"
                  :key="head.id || b"
                  :class="{
                    'v-table-editable-td': head.editable,
                  }"
                  >
                  <input class="v-table-checkbox"
                    v-model="row.check[b]"
                    @click="changeState(row,a,b,$event.target.checked)"
                    @change="sendData({rowId: row.id, headId: head.id, checked: $event.target.checked})"
                    :checked="row.check[b]"
                    type="checkbox" id="checkbox">
                </td>
              </tr>
            </template>
          
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/**
 * Valores recibidos
 * 
 * enviar Nombre para las Columnas en :tittleRows="variableNombre"
 * data: () => ({
 *  variableNombre: "Titulo",
 *})
 * 
 * # HEADERS
 *  -id: number
 * - mask : string (titulo que vamos a mostrar)
 *
 * # ROWS
 * -id: number
 * - mask : string (titulo que vamos a mostrar)
 * - check: array de datos (true, false) para que funcione los checks visuales, si no hay coincidencia mandar falsos (explico mas abajo como hacerlo)
 *  
 * 
 * Ejemplo de mapeo para que funcione ROWS
 * 
 * Creamos unafuncion que crea un array de false para la cantidad de HEADERS que tenemos,
 * 
 * ArrayFalsos(){
 *     return [...Array(this.$store.getters.HEADERS.length || this.$store.getters.HEADERS.count ).fill(false)]
 *   },
 * 
 * rows() {
 *     return this.$store.getters.ROWS.map((i)=>{
 *       const findCloneArray = this.$store.getters.ARRAYDEOBJETOS.find((data)=> data.rows_id == i.id)
 *       return {
 *        id: i.id, --- TRABAJAMOS CON LOS ID
 *        mask: i.NOMBRE A MOSTRAR,
 *        check: this.ArrayFalsos.map((x, index)=>{
 *          let checkWanted = findCloneArray?.headers_id || 0
 *         
 *          for (let i=0; i < checkWanted.length; i++){
 *            if(checkWanted[i] === this.$store.getters.HEADERS.length[index].id ){
 *              return x = true
 *            }
 *          }
 *          return x
 *        }),
 *       }
 *     });
 *   },
 *  
 *  Explico Check
 *  check: mapeamos el array cambiamos los falsos por true en donde coincide el array con los headers
 *
 * 
/**
 * Valores Emitidos
 * Cada Check toma la coincidencia del ROW ID Y HEADER ID
 * {rowId: number, headId: number}
 */
import "./utils/ucwords";
//import loader from "./loader.vue";

export default {
  name: "VueTablesCheckAll",
  components: {

  },
  props: {
    headers: {
      type: Object,
      default: null,
    },
    rows: {
      type: Object,
      default: null,
    },
    titleRows: {
      type: String,
      default: "Columnas",
    },
  },
  data() {
    return {
      opts: {
        tableClass: "table table-hover",
        theadClass: "",
        tbodyClass: "",
      },
      form: [],
      CheckAll: [],
    };
  },
  watch: {
  },
  async mounted() {
    if (!this.headers)
      throw new Error(
        'Input HEADERS is empty, please add data. (eg.: :headers="myHeaders")'
      );
    if (!this.rows)
      throw new Error(
        'Input ROWS is empty, please add data. (eg.: :rows="myRows")'
      );
    await this.completeCheckRows();
  },
  //
  computed: {
    // Se crea un array de los ID de los valores de los headers
    headersLength(){
      let available = [...Array(this.headers.length).keys()].map(i => i+1);
      return available     
    },
    
  },
  methods: {
    // Lo usamos para verificar si todos los checks de la row son true, para marcar como true o false el check all
    completeCheckRows(){
      this.rows.forEach(x=>{
        this.CheckAll.push(this.boxCheck(x))
      })
      return this.CheckAll
    },
    // Cambiamos el state de todos los checks (del boton check all row)
    changeStateAll(rowcheck,check){
      for (let i=0; i < this.headers.length; i++){
       rowcheck[i] = check}
      return rowcheck
    },
    // cambiamos el state del check en particular que tocamos, y enviamos la data para cambiar el state del check all de la row
    changeState(row,indexA,indexB,check){
      row.check[indexB] = check
      this.CheckAll[indexA]= this.boxCheck(row)
      return row.check 
    },
    // lo usamos para saber si toda la row es true
    boxCheck(row){
      return (!row.check.some(x=>!x))
    },
    // emitimos la data del check que cliqueamos.
    sendData({rowId, headId, checked}){
      this.$emit('onCheck', {rowId, headId, checked})
    },
    // viene del check all de la row, creamos los objetos necesarios para enviar a la funcion de sendData que usamos para emitir la info
    changeCheckRows(obj) {
      const newArray = obj.available.map(x=>({
        rowId: obj.rowId, 
        headId: x, 
        checked: obj.checked
      }))
      newArray.forEach(x=>{
        this.sendData(x)
      })
      
    },
  },
};
</script>

<style scoped>
.v-table-responsive-container {
  width: 100%;
  overflow: auto;
}
table {
  width: 100%;
}
table th {
  font-size: 98%;
}
table td,
table tr {
  font-size: 90%;
}

td,
th {
  padding: 5px !important;
}

td {
  border-top: none;
  white-space: nowrap;
  vertical-align: middle !important;
}

thead th {
  border-top: unset !important;
}
</style>
