const baseUrl = process.env.VUE_APP_AUTH_BACKEND_URL;
import axios from "axios";
import {can, token_expired} from '@/acl/methods'

export default {
  state: {
    accesos: {},
    acceso: {},
    clone: {},
    clonearray: {},
  },
  mutations: {
    SET_ACCESOS(state, value) {
      state.accesos = value;
    },
    SET_ACCESO(state, value) {
      state.acceso = value;
    },
    SET_CLONE(state, value) {
      state.clone = value;
    },
    SET_CLONEARRAY(state, value) {
       state.clonearray = value;
    },
  },
  actions: {
    getClonar({ commit, getters }, id,params) {
      if (token_expired(getters.user)){
      // Pongo Post y Put, porque se usa para crear y actualizar nuevo ROL
      if (can(getters.user, 'roles', ['post','put'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}accesos/clonacion/role/${id}`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              params,
            })
            .then((res) => {
              commit("SET_CLONE", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_CLONE", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    getClonarEnArray({ commit, getters }, id,params) {
      if (token_expired(getters.user)){
      // Pongo Put, porque se usa para actualizar Accesos (roles, recursos, permisos)
      if (can(getters.user, 'roles', ['put'])){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}accesos/clonacion/permisosarray/${id}`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              params,
            })
            .then((res) => {
              commit("SET_CLONEARRAY", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      } else {
        commit("SET_CLONEARRAY", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    createAccesos({ getters, commit }, {id,form}) {
      if (token_expired(getters.user)){
      // Se usa para crear los Accesos del nuevo Rol al momento de crear el rol
      if (can(getters.user, 'roles', ['post'])){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .post(`${baseUrl}accesos/${id}`, form, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              this.$toast.success(data);
              resolve(data);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("No tiene permisos para crear accesos");
      }}else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }

    },
    updateAccesos({ getters, commit }, {id,form}) {
      if (token_expired(getters.user)){
      if (can(getters.user, 'roles', ['put'])){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .put(`${baseUrl}accesos/${id}`, form, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              this.$toast.success(data);
              resolve(data);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      } else {
        this.$toast.error("No tiene permisos para editar accesos");
      }}else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  },
  getters: {
    accesos: (state) => state.accesos,
    acceso: (state) => state.acceso,
    clone: (state) => state.clone,
    clonearray: (state) => state.clonearray,
  },
};
