const baseUrl = process.env.VUE_APP_BACKEND_URL;
import axios from "axios";
import {can, token_expired} from '@/acl/methods'

export default {
  state: {
    calendario: [],
  },
  mutations: {
    SET_CALENDARIO(state, value) {
      state.calendario = value;
    },
  },
  actions: {
    getCalendario({getters, commit }, params) {
      if (token_expired(getters.user)){
      if (can(this.getters.user, 'calendario', ['get'])){
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}calendario/calendario/paginado`, {
             headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            params,
          })
          .then((res) => {
            commit("SET_CALENDARIO", res.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });
      }
      else{
        commit("SET_CALENDARIO", {data: []});
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    createCalendario({ getters, dispatch, commit }, form) {
      if (token_expired(getters.user)){
      if(can(getters.user, 'calendario', ['post'])){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .post(`${baseUrl}calendario`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getCalendario");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        this.$toast.error("No tiene permisos para realizar esta acción");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    deleteCalendario({ getters, dispatch, commit }, id) {
      if (token_expired(getters.user)){
      if(can(getters.user, 'calendario', ['delete'])){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .delete(`${baseUrl}calendario/${id}`, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getCalendario");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        this.$toast.error("No tiene permisos para realizar esta acción");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    updateCalendario({ getters, dispatch, commit }, {id, form}) {
      if (token_expired(getters.user)){
      if(can(getters.user, 'calendario', ['put'])){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .put(`${baseUrl}calendario/${id}`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getCalendario");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });}
      else{
        this.$toast.error("No tiene permisos para realizar esta acción");
      }
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  },
  getters: {
    calendario: (state) => state.calendario,
  },
};
