const baseUrl = process.env.VUE_APP_BACKEND_URL;
import axios from "axios";
import {token_expired} from '@/acl/methods'

export default {
  state: {
    contacts: {},
  },
  mutations: {
    SET_CONTACTS(state, value) {
      state.contacts = value;
      state.contacts.data = state.contacts.data.map((x) => {
        x.onSetRead = !x.is_read;
        x.onSetUnread = !!x.is_read;
        return x;
      });
    },
  },
  actions: {
    getContacts({ commit, getters }, params) {
      if (token_expired(getters.user)){
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}contacts`, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
            params,
          })
          .then((res) => {
            commit("SET_CONTACTS", res.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    updateContact({ getters, dispatch, commit }, { id, form }) {
      if (token_expired(getters.user)){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .put(`${baseUrl}contacts/${id}`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getContacts");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
    deleteContact({ getters, dispatch, commit }, id) {
      if (token_expired(getters.user)){
      commit("SHOW_LOADER");
      return new Promise((resolve) => {
        axios
          .delete(`${baseUrl}contacts/${id}`, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
          })
          .then(({ data }) => {
            dispatch("getContacts");
            this.$toast.success(data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            commit("HIDE_LOADER");
          });
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },
  },
  getters: {
    contacts: (state) => state.contacts,
  },
};
