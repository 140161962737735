<template>
  <div>
    <div class="v-table-responsive-container">
      <table :class="opts.tableClass">
        <thead :class="opts.theadClass">
          <tr> 
            <th class="text-start">
              {{ tittleRows }}
            </th>
            <th class="text-center">
              Casilla de verifiación
            </th>
            <th style="width: 1%" />
          </tr>
        </thead>
        <tbody :class="opts.tbodyClass">
            <template v-for="row in rows" :key="row.id">
              <tr>
                <th class="text-start">
                  {{row.mask.ucwords()}}
                </th>
                <td>
                  <input class="v-table-checkbox"
                    @change="sendData({rowId: row.id, checked: $event.target.checked})"
                    :checked="row.available"
                    type="checkbox" id="checkbox">
                </td>
              </tr>
            </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/**
 * Valores recibidos
 *
 *
 * # ROWS
 * -id: number
 * - mask : string / nullable
 * - available: true/false
 *  
 * ejemplo de mapeo para que funcione ROWS
 *  
 * mapeamos los datos que vamos a usar en la tabla
 *  mapeo de datos(){
 *     return this.$store.getters.INFOAINJECTAR.rows.map(x=>({
 *       variableAComparar: x.variableAComparar,
 *       available: true
 *     }))
 *   }
 * 
 * aca mapeamos la row, injectamos true cuando la varible de la data que traemos con la row coincide en true, y si no tiene ponemos false
 * rows(){
 *     return this.$store.getters.permisosGenerales.rows.map((i)=>{
 *       let findAvailable = this.INFOAINJECTAR.find((data)=> data.variableAComparar == i.variableAComparar)?.available || false
 *       return {
 *       id: i.id,
 *       mask: i.permiso_general_name,
 *       available: findAvailable
 *     }}
 *   )},
 * 
/**
 * Valores Emitidos
 * Cada Check toma la coincidencia del ROW ID
 * {rowId: number}
 */
import "./utils/ucwords";
//import loader from "./loader.vue";

export default {
  name: "VueTablesPermisosGenerales",
  components: {

  },
  props: {
    rows: {
      type: Object,
      default: null,
    },
    tittleRows: {
      type: String,
      default: "Columna",
    },
  },
 
  data() {
    return {
      opts: {
        tableClass: "table table-hover",
        theadClass: "",
        tbodyClass: "",
      },
      checked: undefined,
      form: [],
    };
  },
  watch: {
  },
  mounted() {
    if (!this.rows)
      throw new Error(
        'Input ROWS is empty, please add data. (eg.: :rows="myRows")'
      );
  },
  methods: {
    sendData({rowId, checked}){
      this.$emit('onCheck', {rowId, checked})
    },
  },
};
</script>

<style scoped>
.v-table-responsive-container {
  width: 100%;
  overflow: auto;
}
table {
  width: 100%;
}
table th {
  font-size: 98%;
}
table td,
table tr {
  font-size: 90%;
}

td,
th {
  padding: 5px !important;
}

td {
  border-top: none;
  white-space: nowrap;
  vertical-align: middle !important;
}

thead th {
  border-top: unset !important;
}
</style>
