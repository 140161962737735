import { createRouter, createWebHistory } from "vue-router";
import {isAllowed} from '@/acl/methods.js'
import store from "@/store";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: () => import("@/views/Auth/Login.vue"),
  },
  {
    path: "/register",
    component: () => import("@/views/Auth/Register.vue"),
  },
  {
    path: "/",
    component: () => import("@/views/Dashboard/DashboardLayout.vue"),
    children: [
      {
        path: "dashboard",
        name: 'dashboard',
        component: () => import("@/views/Dashboard/Dashboard.vue"),
      },
      {
        path: "contenidos",
        name: 'contenidos',
        component: () => import("@/views/Dashboard/Contenidos/Contenidos.vue"),
        meta:{
          module: 'contenidos',
        }
      },
      {
        path: "contenidos-reglamentos",
        name: 'contenidos-reglamentos',
        component: () => import("@/views/Dashboard/Contenidos/ContenidosREGLAMENTOS.vue"),
        meta:{
          module: 'contenidos',
        }
      },
      {
        path: "contenidos-blog",
        name: 'ccontenidos-blog',
        component: () => import("@/views/Dashboard/Contenidos/ContenidosBLOG.vue"),
        meta:{
          module: 'contenidos',
        }
      },
      {
        path: "contenidos-reglamento-adiestramiento",
        name: 'contenidos-reglamento-adiestramiento',
        component: () => import("@/views/Dashboard/Contenidos/ContenidosREGLAMENTO-ADIESTRAMIENTO.vue"),
        meta:{
          module: 'contenidos',
        }
      },
      {
        path: "contenidos-reglamento-torneo",
        name: 'contenidos-reglamento-torneo',
        component: () => import("@/views/Dashboard/Contenidos/ContenidosREGLAMENTO-TORNEO.vue"),
        meta:{
          module: 'contenidos',
        }
      },
      {
        path: "contenidos-informacion-util",
        name: 'contenidos-informacion-util',
        component: () => import("@/views/Dashboard/Contenidos/ContenidosINFORMACION-UTIL.vue"),
        meta:{
          module: 'contenidos',
        }
      },
      {
        path: "contenidos-ultimas-noticias",
        name: 'contenidos-ultimas-noticias',
        component: () => import("@/views/Dashboard/Contenidos/ContenidosULTIMAS-NOTICIAS.vue"),
        meta:{
          module: 'contenidos',
        }
      },
      {
        path: "contenidos-resultados",
        name: 'contenidos-resultados',
        component: () => import("@/views/Dashboard/Contenidos/ContenidosRESULTADOS.vue"),
        meta:{
          module: 'contenidos',
        }
      },
      {
        path: "contenidos-institucion",
        name: 'contenidos-institucion',
        component: () => import("@/views/Dashboard/Contenidos/ContenidosINSTITUCION.vue"),
        meta:{
          module: 'contenidos',
        }
      },
      {
        path: "videos",
        component: () => import("@/views/Dashboard/Videos/Videos.vue"),
      },
      {
        path: "contactos",
        component: () => import("@/views/Dashboard/Contactos/Contactos.vue"),
      },
      {
        path: "galerias",
        name: 'galerias',
        component: () => import("@/views/Dashboard/Galerias/Galerias.vue"),
        meta:{
          module: 'galerias',
        },
      },
      {
        path: "galeriasPublicas",
        name: 'galeriasPublicas',
        component: () => import("@/views/Dashboard/GaleriasPublicas/GaleriasPublicas.vue"),
        meta:{
          module: 'galerias',
        },
      },
      {
        path: "galeriasPublicasDetails",
        name: 'galeriasPublicasDetails',
        component: () => import("@/views/Dashboard/GaleriasPublicasDetails/GaleriasPublicas.vue"),
        meta:{
          module: 'galerias',
        },
      },
      {
        path: "calendario",
        name: 'calendario',
        component: () => import("@/views/Dashboard/Calendario/Calendario.vue"),
        meta:{
          module: 'calendario',
        },
      },
      {
        path: "links",
        name: 'links',
        component: () => import("@/views/Dashboard/Links/Links.vue"),
        meta:{
          module: 'links',
        },
      },
      {
        path: "users",
        name: 'users',
        component: () => import("@/views/Dashboard/Usuarios/Profiles.vue"),
        meta:{
          module: 'users'
        }
      },
       {
        path: "usersdeleted",
        component: () => import("@/views/Dashboard/Usuarios/ProfilesDeleted.vue"),
      },
      {
        path: "roles",
        component: () => import("@/views/Dashboard/Roles/Roles.vue"),
        meta:{
          module: 'roles'
        }
      },
       {
        path: "rolesdeleted",
        component: () => import("@/views/Dashboard/Roles/RolesDeleted.vue"),
      },
      {
        path: "opciones",
        component: () => import("@/views/Dashboard/MyUser/Options.vue"),
      },
      {
        path: "categorias",
        name: 'categorias',
        component: () => import("@/views/Dashboard/Categorias/Categorias.vue"),
        meta:{
          module: 'categorias',
        },
      },
      {
        path: "categorias-torneos",
        name: 'categorias-torneos',
        component: () => import("@/views/Dashboard/CategoriasTorneos/CategoriasTorneos.vue"),
      },
      {
        path: "jueces",
        name: 'jueces',
        component: () => import("@/views/Dashboard/Jueces/Jueces.vue"),
      },
      {
        path: "comite-ejecutivo",
        name: 'comite-ejecutivo',
        component: () => import("@/views/Dashboard/ComiteEjecutivo/Comite.vue"),
      },
      {
        name: "subir-revista",
        path: "revistas/subir",
        component: () => import("@/views/Revistas/Subir.vue")
      }
    ],
  },
  {
    path: "/torneos",
    component: () => import("@/views/Dashboard/DashboardLayout.vue"),
    children: [
      {
        path: "inscripcion",
        name: 'torneos-inscripcion',
        component: () => import("@/views/Dashboard/Torneos/Inscripcion.vue"),
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const {module} = to.meta;
  if(module){
    if(store.getters.user){
      if (isAllowed(store.getters.user, module))return next();
    }else{
      if(localStorage.getItem('token')){
        await store.dispatch('setCredentials')
        if (isAllowed(store.getters.user, module))return next();
      }
    }
    return next({name: 'dashboard'})
  }
  next();
});

export default router;
