import {modules, perms, permisosGenerales} from './constants'

export const token_expired = (user) => {
  if (!user?.time_dead) return false
  const UNIX = Date.now()
  if (UNIX < user.time_dead) return true
  return false
}

export const can = (user, module, actions) => {
  if (!user) return false
  const permisos = user.permisos
  const mod = modules[module.toUpperCase()]
  const hasPerm = actions.some(action => {
    const perm = perms[action.toUpperCase()]
    return permisos[mod] && permisos[mod][perm]
  })
  return hasPerm
}

export const isAllowed = (user, module) => {
  if (!user) return false;
  const mod = modules[module.toUpperCase()]
  const permisos = user.permisos
  if (permisos[mod]) return true
  else return false
}

export const canPG = (user, permisoGeneral) => {
  if (!user) return false
  const permisosGeneralesUser = user.permisos_generales // [1,2.3] || []
  const PG = permisosGenerales[permisoGeneral.toUpperCase()] // {G_D_USER:1}
  const hasPermGeneral = permisosGeneralesUser.includes(PG)
  return hasPermGeneral

}
